import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
    CognitoIdentityProviderClient,
    GetUserCommand,
  } from "@aws-sdk/client-cognito-identity-provider";

  const DEFAULT_REGION = "us-east-1";


var client = new CognitoIdentityProviderClient({
  region: DEFAULT_REGION,
  credentials: {
  
  }
})
//Services

//Context

const AuthContext = createContext(null)
export const AuthProvider  = ({children}) => {
    const [getuser, setuser] = useState(null)
    const ConvertTokenToUserid = async (myjwttoken) => {

        const command = new GetUserCommand({
          AccessToken: myjwttoken,
        });
        const userid = await client.send(command);
        setuser(userid)
        return userid;
      };
    
    
    return(
        <AuthContext.Provider value={{getuser, ConvertTokenToUserid}}>{children}</AuthContext.Provider>
    )
}



export const useAuth = () => {
	return useContext(AuthContext);
};